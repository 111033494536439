:root {
  --orange-primary: #ffaa80;
  --yellow-accent: #ffd93d;
  --cream-bg: #f6f1e9;
  --brown-dark: #4f200d;
  --orange-secondary: #ffbb5c;
  --black-light: #212529;
  --red-error: #EC7272;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--cream-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
